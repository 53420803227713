export default defineI18nConfig(() => ({
  legacy: false,
  locale: 'en',
  silentTranslationWarn: true,
  silentFallbackWarn: true,
  missingWarn: false,
  fallbackWarn: false,
  fallbackLocale: 'en',
  datetimeFormats: {
    en: {
      shortDate: {
        dateStyle: 'short',
      },
      weekDay: {
        weekday: 'long',
      },
    },
    fr: {
      shortDate: {
        dateStyle: 'short',
      },
      weekDay: {
        weekday: 'long',
      },
    },
  },
}))
